import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  {
    pid: 0,
    isTokenOnly: true,
    lpSymbol: 'SquidGrow',
    lpAddresses: {
      97: '',
      56: '0x07D01675893986A1341563986bEeBf589fe66DB4',
    },
    token: serializedTokens.nsbc,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 1,
    lpSymbol: 'SquidGrow-BNB',
    lpAddresses: {
      97: '',
      56: '0x07D01675893986A1341563986bEeBf589fe66DB4',
    },
    token: serializedTokens.nsbc,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 2,
    lpSymbol: 'BNB-BUSD',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    token: serializedTokens.wbnb,
    quoteToken: serializedTokens.busd,
  },
]

export default farms
